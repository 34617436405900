/* eslint-disable import/order */
import React from 'react';

import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import 'core-js/es/map';
import 'core-js/es/set';
// import "es5-shim/es5-sham"
// import"es5-shim/es5-shim";

// import './index.css';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
