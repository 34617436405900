import * as React from 'react';

import { Modal, ModalHead, ModalContent, ModalFooter, Typography, Button } from '@blueprint/ui';

import styles from './index.module.scss';

export type ModalFuncProps = {
  cancelText?: string;
  onCancel?: () => void;
  okText?: string;
  onOk?: () => void;
  title: string;
  content: string;
  closeIcon?: boolean;
  visible: boolean;
};

export const ModalPopup = (props: ModalFuncProps) => {
  const {
    visible,
    closeIcon,
    onCancel,
    onOk,
    cancelText = '取消',
    content,
    title,
    okText = '确定',
  } = props;
  return (
    <Modal
      open={visible}
      closeIcon={closeIcon}
      closeIconProps={{
        onClick: onCancel,
      }}
      size={'small'}
      activeFocusTrap={true}
      className={styles.dialog}
      closeIconAriaLabel="关闭"
      closeIconTitleAccess="关闭"
      aria-labelledby="modal-title modal-content"
    >
      <ModalHead display="flex" alignItems="center">
        <Typography variant="h1" fontWeight="300" id="modal-title">
          {title}
        </Typography>
      </ModalHead>
      <ModalContent>
        <Typography variant="body1" className={styles['modal-content']} id="modal-content">
          {content}
        </Typography>
      </ModalContent>
      <ModalFooter display="flex" justifyContent="space-between">
        <Button variant="light.tertiary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button onClick={onOk}>{okText}</Button>
      </ModalFooter>
    </Modal>
  );
};
