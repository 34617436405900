import React from 'react';

import Loadable from 'react-loadable';

import Loading from '../common/loadingComponent/loading';

export default (component) => {
  return Loadable({
    loader: component,
    loading() {
      return <Loading />;
    },
  });
};
