import { combineReducers } from 'redux';

import homeReducer from './homeModule';
import infoReducer from './informationModule';
import LogonReducer from './logonModule';
import uploadFileReducer from './uploadFileModule';

export default combineReducers({
  logon: LogonReducer,
  home: homeReducer,
  info: infoReducer,
  upload: uploadFileReducer,
});
