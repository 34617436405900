/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import axios from 'axios';

import {
  serverErrorAction,
  showHideLodingAction,
  fileListActionResult,
} from '../redux/actionCreaters';
import { restSessionTimeOut } from '../redux/sessionTimeoutModule';
import store from '../store/index';

const messageId = setMessageId();
const headers = {
  accept: 'application/json',
  'content-type': 'application/json',
  messageId: messageId,
  accessToken: '',
};

console.log(process.env);
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.timeout = 180000;
axios.defaults.headers = headers;

axios.interceptors.request.use(
  (config) => {
    config.headers.accessToken = sessionStorage.getItem('accessToken');

    try {
      const sessionId = new URLSearchParams(window.location.hash.split('?')[1]).get('sessionId');
      const otCode = new URLSearchParams(window.location.hash.split('?')[1]).get('otCode');

      config.headers = {
        ...config.headers,
        sessionId,
        otCode,
      };
    } catch (error) {}
    return config;
  },
  (config) => {
    console.log(config);
    return config;
  },
);

axios.interceptors.response.use(
  (data) => {
    //  console.log(data,"================config",data.config);
    const config = data.config;
    const returnCode = get(data, 'data.common.returnCode');

    if (config.url.indexOf('Upload') > -1 && returnCode === 'YANGGUAN-027') {
      const common = {
        returnCode: 'request-timeOut-111',
        returnReason: 'request time out',
      };
      const data = {
        orderNumber: config.data.get('orderNumber'),
        transactionNumber: config.data.get('transactionNumber'),
        fileId: config.data.get('fileId'),
      };

      store.dispatch(fileListActionResult({ data: { common, data } }));
    }

    if (returnCode === 'YANGGUAN-000') {
      store.dispatch(restSessionTimeOut(true));
    }

    //save sid for request every time
    //sessionStorage.setItem('sid', data.data.common.sid || '');

    return data;
  },
  function reTryInterceptors(err) {
    // console.log(err)
    var config = err.config;

    // console.log(config)
    // console.log("request time out========",err);
    if (config.url.indexOf('Upload') > -1) {
      const common = {
        returnCode: 'request-timeOut-111',
        returnReason: 'request time out',
      };
      const data = {
        orderNumber: config.data.get('orderNumber'),
        transactionNumber: config.data.get('transactionNumber'),
        fileId: config.data.get('fileId'),
      };

      store.dispatch(fileListActionResult({ data: { common, data } }));
    }
  },
);

export const get = (url, data, callback) => {
  if (typeof url !== 'string') {
    console.log(typeof url);
    return false;
  }

  if (typeof callback !== 'function') {
    console.log(typeof callback);
    return false;
  }

  var reqData = '';

  for (const key in data) {
    reqData += key + '=' + data[key] + '&';
  }

  reqData = reqData.slice(0, reqData.length - 1);

  if (/[u4e00-u9fa5]/g.test(reqData)) {
    reqData = encodeURI(reqData);
  }

  const reqUrl = `${url}${reqData ? `?${reqData}` : ``}`;

  axios
    .get(reqUrl)
    .then((res) => {
      callback(res);
    })
    .catch(() => {
      handleError();
      store.dispatch(serverErrorAction(true));
      store.dispatch(showHideLodingAction(false));
      setTimeout(() => {
        store.dispatch(serverErrorAction(false));
      }, 5000);
    });
};

export const post = (url, param, callback) => {
  try {
    axios
      .post(url, param)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        console.log('----------------', error);
        handleError();
        store.dispatch(serverErrorAction(true));
        store.dispatch(showHideLodingAction(false));
        setTimeout(() => {
          store.dispatch(serverErrorAction(false));
        }, 5000);
      });
  } catch (error) {
    console.log('catch  error', error);
  }
};

function handleError(error) {
  console.log(error);
}

function setMessageId() {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + '-' + S4() + '-' + S4() + '-' + S4();
}
