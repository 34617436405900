import React, { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

// import Dialog from '@/common/dialog';
import ModalPopup from '@/common/dialog';
import * as actionCreaters from '@/redux/actionCreaters';
import { keepAlive } from '@/redux/sessionTimeoutModule';

import useTimeout from './useTimeout';

export function SessionTimeout() {
  const dispatch = useDispatch();
  const { isOpenTimeoutModal, countdown, cleanInterval } = useTimeout();
  const logout = useCallback(() => {
    cleanInterval();
    dispatch(actionCreaters.logoffAction());
  }, [cleanInterval, dispatch]);
  const onContinue = useCallback(() => {
    cleanInterval();
    dispatch(keepAlive());
  }, [cleanInterval, dispatch]);

  useEffect(() => {
    if (countdown === 0) {
      logout();
    }
  }, [logout, countdown]);

  return (
    <ModalPopup
      visible={isOpenTimeoutModal}
      cancelText="关闭"
      onCancel={logout}
      okText="继续"
      onOk={onContinue}
      title="页面会话即将超时提醒"
      content={`当前页面将于${countdown && Math.round(countdown)}
            秒后超时，如果想继续停留在当前页面请点击“继续”。点击关闭按钮将返回登陆页面。`}
    />
  );
}
