import React from 'react';

import { ThemeProviderV2 } from '@blueprint/ui';
// import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';

import /* webpackChunkName: "antd" */ 'antd/dist/antd.css';
import './common/styles/reset.scss';
import { SessionTimeout } from '@/components/sessionTimeout';

import AsyncLoad from './utils/loadable';

// import "./assets/icon/hsbcIconFonts/stylesheet.css"

import './assets/HSBC-icon-v1.1/style.css';

const Loading = AsyncLoad(() =>
  import(/* webpackChunkName: "loading" */ './common/loadingComponent/loading'),
);
const ErrorPopUp = AsyncLoad(() =>
  import(/* webpackChunkName: "errorPopup" */ './common/errorPopup/errorPopup'),
);
const HomePage = AsyncLoad(() =>
  import(/* webpackChunkName: "homepage" */ './containers/homePage'),
);
const Information = AsyncLoad(() => import('./containers/information'));
const Logon = AsyncLoad(() => import('./containers/logon'));
const NewLogon = AsyncLoad(() => import('./containers/new-logon'));
const FileUpload = AsyncLoad(() => import('./containers/fileUpload'));
const SuccessPage = AsyncLoad(() => import('./containers/successPage'));
const FileSuccessPage = AsyncLoad(() => import('./containers/fileSuccessPage'));
const TermAndCondition = AsyncLoad(() => import('./common/termAndCondition/index'));
const RelationshipTerms = AsyncLoad(() => import('./common/termAndCondition/relationshipTerms'));
const Routers = () => (
  <ThemeProviderV2 theme={undefined}>
    <HashRouter basename="/">
      <Loading />
      <ErrorPopUp />
      <SessionTimeout />
      <Switch>
        <Route path="/logon" component={NewLogon} />
        <Route path="/old-logon" component={Logon} />
        <Route path="/home" component={HomePage} />
        <Route path="/info" component={Information} />
        <Route path="/upload" component={FileUpload} />
        <Route path="/success" component={SuccessPage} />
        <Route path="/fileSuccess" component={FileSuccessPage} />
        <Route path="/termAndCondition" component={TermAndCondition} />
        <Route path="/relationshipTerms/:lang" component={RelationshipTerms} />
        <Route path="/" component={NewLogon} />
      </Switch>
    </HashRouter>
  </ThemeProviderV2>
);

function App() {
  return <Routers />;
}

export default App;
