/* eslint-disable no-console */
import * as constants from './constants';

const defaultState = {
  traRefNumList: [],
  fileList: {},
  poNumListSize: '',
  traRefNumListSize: '',
  confirmListStatus: {},
};

export default (state = defaultState, action) => {
  if (action.type === constants.GET_TRACK_LIST) {
    const { traRefNumList, poNumListSize, traRefNumListSize } = action.data || {};

    return {
      ...state,
      traRefNumList: traRefNumList || [],
      poNumListSize: poNumListSize || 0,
      traRefNumListSize: traRefNumListSize || 0,
    };
  }

  if (action.type === constants.UPLOAD_FILE_LIST) {
    const fileList = action.data.data;
    return { ...state, fileList: fileList };
  }

  if (action.type === constants.CONFIRM_UPLOAD_FILES) {
    const confirmListStatus = action.data;
    return { ...state, confirmListStatus, traRefNumList: [] };
  }
  return state;
};
