const links = {
  verifySeller: '/verify_seller',
  logon: '/logon',
  getSellerInfo: '/get_seller_info',
  updateSellerInfo: '/update_seller_info',
  getBankInfo: '/get_bank_info',
  getPoList: '/get_po_num_list',
  uploadFilesStatus: '/fileUpload',
  logoff: '/logout',
  getTrackList: '/get_tra_ref_num_and_po_num_list',
  confirmUploadFiles: '/confirm_upload_files',
  reNewSession: '/session_expire',
  getPermisssion: '/get_seller_permissions',
  getAreaDicData: '/get_area_dic',
  getEmarkets: '/get_ecom_platform_data',
};

export default links;
