import _ from 'lodash';

import * as constants from './constants';

const logonDefault = {
  isLogin: false,
  isSendOtp: false,
  isInvalidEmarket: false,
  isSellerNameError: false,
  isOTPError: false,
  showLoading: false,
  sellerType: '' || 'corporate',
  sellerName: '',
  // internalSellerId:""||sessionStorage.getItem("internalSellerId"),
  permissions: {},
  emarkets: [],
  emarketcode: undefined,
  sellerStatus: '',
  timeFlag: '',
  isOTPErrorTimes: null,
  prohibitedTime: null,
  registrationStatus: '', // 'Pending Review' or 'reKYC' button submit  is enabled.  'Completed' : button submit is disabled
};
const emarketStatusCode = ['YANGGUAN-073'];

export default (state = logonDefault, action) => {
  const common = _.get(action, 'data.common', {});
  const returnCode = common.returnCode;

  if (action.type === constants.LOGON) {
    const sellerId = action.data.corporateNum || action.data.individualNum;
    const isInvalidEmarket = emarketStatusCode.indexOf(returnCode) > -1;
    const isOTPError = returnCode === 'YANGGUAN-011' || returnCode === 'YANGGUAN-012';
    const verifySeller = ['YANGGUAN-009', 'YANGGUAN-010', 'YANGGUAN-002'].indexOf(returnCode) > -1;

    if (action.data.data && !verifySeller && !isOTPError) {
      var { permissions, sellerStatus } = action.data.data && action.data.data;

      sessionStorage.setItem('isLogin', 'true');
      sessionStorage.setItem('sellerStatus', sellerStatus);
      sessionStorage.setItem('sellerName', sellerId);
      sessionStorage.setItem('emarketcode', action.data.emarketcode);
    }

    if (action.data.data) {
      const { errTime, prohibitedTime } = action.data.data;
      return {
        ...state,
        isLogin: true,
        isInvalidEmarket,
        sellerType: action.data.sellerType,
        sellerName: sellerId,
        permissions: permissions || {},
        isOTPError,
        isSellerNameError: verifySeller,
        sellerStatus,
        timeFlag: new Date().getTime(),
        isOTPErrorTimes: errTime || 0,
        prohibitedTime,
        emarketcode: action.data.emarketcode,
      };
    }
    return {
      ...state,
      isSendOtp: false,
      isLogin: true,
      sellerType: action.data.sellerType,
      sellerName: sellerId,
      permissions: permissions || {},
      isOTPError,
      isInvalidEmarket,
      isSellerNameError: verifySeller,
      sellerStatus: sellerStatus,
      timeFlag: new Date().getTime(),
    };
  }

  if (action.type === constants.SEND_OTP) {
    const data = action.data;
    const responseTime = common.responseTime;
    const errorList = ['YANGGUAN-009', 'YANGGUAN-010', 'YANGGUAN-002'];
    const newState = JSON.parse(JSON.stringify(state));
    var verifySeller = errorList.indexOf(returnCode) > -1;
    const isInvalidEmarket = emarketStatusCode.indexOf(returnCode) > -1;
    const errTime = _.get(data, 'data.errTime');
    const prohibitedTime = _.get(data, 'data.prohibitedTime');

    if (errTime || prohibitedTime) {
      return {
        ...newState,
        isSendOtp: !verifySeller,
        isSellerNameError: verifySeller,
        isInvalidEmarket,
        isOTPError: true,
        timeFlag: responseTime,
        isOTPErrorTimes: errTime || 0,
        prohibitedTime: prohibitedTime,
      };
    }
    return {
      ...newState,
      isSendOtp: !verifySeller,
      isSellerNameError: verifySeller,
      isOTPError: false,
      isInvalidEmarket,
      timeFlag: responseTime,
      isOTPErrorTimes: 0,
    };
  }

  if (action.type === constants.SHOW_HIDE_LOADING) {
    const newState = JSON.parse(JSON.stringify(state));
    return { ...newState, showLoading: action.show };
  }

  if (action.type === constants.LOGOFF) {
    const newState = JSON.parse(JSON.stringify(state));

    sessionStorage.setItem('isLogin', '');
    return { ...newState, isLogin: false };
  }

  if (action.type === constants.GET_EMARKETS) {
    localStorage.setItem('emarkets', JSON.stringify(action.data));
    return {
      ...state,
      emarkets: _.map(action.data, (item) => {
        return { ...item, label: _.get(item, 'description'), value: _.get(item, 'emarketcode') };
      }),
    };
  }
  return state;
};
