export const NET_WORK_ERROR = 'net_work_error';
export const LOGON = 'logon/logon';
export const SEND_OTP = 'logon/send_otp';
export const GET_EMARKETS = 'logon/get_emarkets';
export const GET_SELLER_INFO = 'info/get_seller_info';
export const INIT_SELLER_INFO = 'info/init_seller_info';
export const UPDATE_SELLER_INFO = 'info/update_seller_info';
export const GET_BANK_INFO = 'info/get_bank_info';
export const SHOW_HIDE_LOADING = 'show_hide_loading';
export const LOGOFF = 'logoff/logoff';
export const UPDATE_SELLER_INFO_ERROR = 'info/update_seller_info_error';
export const GET_TRACK_LIST = 'upload/get_tra_ref_num_and_po_num_list';
export const UPLOAD_SUCCESS = 'fileUpload/upload_success';
export const UPLOAD_FILE_LIST = 'upload/upload_files';
export const UPDATE_SELLER_TC_INFO = 'update_seller_tc_info';
export const ERROR_MESSAGE_INFO = 'error_message_info';
export const CONFIRM_UPLOAD_FILES = 'cofirm_upload-files';
export const SESSION_TIME_OUT = 'session_time_out';
export const GET_PERMISSION = 'home/get_permission';
export const AREA_DIC = 'info/area_dic';
export const REST_SESSION_TIMEOUT = 'rest_session_timeout';
