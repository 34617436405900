/* eslint-disable padding-line-between-statements */
import { NET_WORK_ERROR, SESSION_TIME_OUT, GET_PERMISSION } from './constants';

const homeState = {
  serverError: false,
  msg: '系统出错，请稍后重试。',
  sessionTimeOut: false,
  // entitlement:[
  //     {title:"注册信息",path:"/info"},
  //     {title:"文件上传",path:"/upload"}
  // ],
  permissions: {},
  sellerStatus: '',
};

export default (state = homeState, action) => {
  if (action.type === NET_WORK_ERROR) {
    if (action.data.msg) {
      const { serverError, msg } = action.data;
      return { ...state, serverError, msg };
    }
    return { ...state, serverError: action.data, msg: '系统出错，请稍后重试。' };
  }
  if (action.type === SESSION_TIME_OUT) {
    return { ...state, sessionTimeOut: action.data };
  }
  if (action.type === GET_PERMISSION) {
    const data = action.data.data;
    return { ...state, permissions: data.permissions, sellerStatus: data.sellerStatus };
  }
  return state;
};

export const getEntitlement = (state) => {
  const permissions = state.home.permissions;
  const sellerStatus = state.home.sellerStatus;
  const entitlement = [];

  if (
    permissions['registrationForm'] === 'true' ||
    ['Freeze_1', 'Freeze_2', 'Freeze_3'].indexOf(sellerStatus) > -1
  ) {
    entitlement.push({
      buttonInfo: '注册信息',
      path: '/info',
      ['registrationForm']: 'true',
      title: '提交商户信息，注册成功后便可使用汇丰银行跨境支付结算服务',
      iconImg: 'icon-account',
    });
  }
  if (permissions['uploadFile'] === 'true') {
    entitlement.push({
      buttonInfo: '我要上传文件',
      path: '/upload',
      ['uploadFile']: 'true',
      title: '请按照相关要求上传文件，以满足监管检查要求',
      iconImg: 'icon-upload1',
    });
  }

  return entitlement;
};
