import links from '@/services/api';

import { get } from '../services/request';

import * as constants from './constants';

const sessionTimeoutDefault = {
  isRestSessionTimeOut: false,
};

export default (state = sessionTimeoutDefault, action: any) => {
  if (action.type === constants.REST_SESSION_TIMEOUT) {
    return { ...state, isRestSessionTimeOut: action.isRestSessionTimeOut };
  }

  return state;
};

export const restSessionTimeOut = (isRestSessionTimeOut: boolean) => {
  return {
    type: constants.REST_SESSION_TIMEOUT,
    isRestSessionTimeOut,
  };
};

export const keepAlive = () => {
  return () => {
    get(links.reNewSession, { isRenew: true }, (res: any) => {
      if (res?.data?.accessToken) {
        sessionStorage.setItem('accessToken', res?.data?.accessToken);
      }
    });
  };
};
