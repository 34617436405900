import { useEffect, useState } from 'react';

import { useInterval } from 'ahooks';

const SESSION_TIMEOUT = 30 * 60; // token 过期时间30min
const SESSION_REMAINING = 60; // 弹框倒计时时间60s

export function useTimeout() {
  const [isOpenTimeoutModal, setOpenTimeoutModal] = useState(false);
  const [countdown, setCountdown] = useState<number | undefined>(SESSION_TIMEOUT);

  useInterval(() => {
    if (!countdown || countdown <= 0) {
      return;
    }

    setCountdown(countdown - 1);
  }, 1000);
  useEffect(() => {
    if (countdown && countdown <= SESSION_REMAINING) {
      if (!isOpenTimeoutModal) {
        setOpenTimeoutModal(true);
      }
    } else {
      setOpenTimeoutModal(false);
    }
  }, [countdown, isOpenTimeoutModal]);

  const cleanInterval = () => {
    setCountdown(SESSION_TIMEOUT);
    setOpenTimeoutModal(false);
  };
  return { isOpenTimeoutModal, countdown, cleanInterval } as const;
}

export default useTimeout;
