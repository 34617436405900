/* eslint-disable max-lines */
/* eslint-disable no-console */
import _ from 'lodash';

import links from '../services/api';
import { post, get } from '../services/request';
import history from '../utils/history';

import {
  NET_WORK_ERROR,
  LOGON,
  SEND_OTP,
  INIT_SELLER_INFO,
  GET_BANK_INFO,
  UPDATE_SELLER_INFO,
  SHOW_HIDE_LOADING,
  LOGOFF,
  UPDATE_SELLER_INFO_ERROR,
  GET_TRACK_LIST,
  GET_EMARKETS,
  UPLOAD_FILE_LIST,
  CONFIRM_UPLOAD_FILES,
  SESSION_TIME_OUT,
  GET_PERMISSION,
  AREA_DIC,
} from './constants';

const StatusCode = {
  'YANGGUAN-000': '200', //200
  'YANGGUAN-001': 'invalid parameter', //400
  'YANGGUAN-002': 'invalid seller', //403
  'YANGGUAN-003': 'systerm error', //
  'YANGGUAN-004': 'the parameter is missing', //
  'YANGGUAN-005': 'the parameter is illegal', //
  'YANGGUAN-006': 'the request method is not support',
  'YANGGUAN-007': 'connection API failed',
  'YANGGUAN-027': 'session timeout',
  'YANGGUAN-030': 'repeat logon',
  'YANGGUAN-031': 'cache get sellerInfo falid,please re-logon',
  'YANGGUAN-068': 'section country check failed',
  'YANGGUAN-069': 'section country request failed',
  'YANGGUAN-070': 'get seller ip is null',
};
const handleStatus = (status, dispatch) => {
  if (StatusCode[status] === '200') {
    dispatch(serverErrorAction(false));
    return false;
  }

  if (status === 'YANGGUAN-027') {
    dispatch(sessionTimeoutAction(true));
    setTimeout(() => {
      dispatch(serverErrorAction(false));
    }, 5000);
    return true;
  }

  if (status === 'YANGGUAN-031') {
    dispatch(serverErrorAction(true));
    history.replace('/');
    window.location.reload();
    setTimeout(() => {
      dispatch(serverErrorAction(false));
    }, 5000);
    return true;
  }

  if (status === 'YANGGUAN-030') {
    dispatch(serverErrorAction({ serverError: true, msg: '您的帐号已在别处登录！' }));
    setTimeout(() => {
      history.replace('/');
      window.location.reload();
      dispatch(serverErrorAction(false));
    }, 4000);
    return true;
  }

  if ('YANGGUAN-068' === status) {
    dispatch(
      serverErrorAction({
        serverError: true,
        msg: '无法登录，汇丰未在您当前所在地区提供服务',
      }),
    );
    setTimeout(() => {
      // window.close();
    }, 4000);
    return true;
  }

  if (StatusCode[status]) {
    dispatch(serverErrorAction(true));
    setTimeout(() => {
      dispatch(serverErrorAction(false));
    }, 5000);
    return true;
  }

  return false;
};

export const serverErrorAction = (data) => {
  return {
    type: NET_WORK_ERROR,
    data,
  };
};

export const sessionTimeoutAction = (data) => {
  return {
    type: SESSION_TIME_OUT,
    data,
  };
};

export const showHideLodingAction = (show) => {
  return {
    type: SHOW_HIDE_LOADING,
    show,
  };
};

const checkIsLogin = (state) => {
  const isLogin = state.logon.isLogin || sessionStorage.getItem('isLogin');

  if (!isLogin) {
    return false;
  }
  return true;
};
const logonActoinResult = (data) => {
  return {
    type: LOGON,
    data,
  };
};

export const logonActoin = (reqData) => {
  return (dispatch) => {
    dispatch(showHideLodingAction(true));
    dispatch(serverErrorAction(false));

    post(links['logon'], reqData, (res) => {
      const common = res.data.common;
      const isError =
        common.returnCode != 'YANGGUAN-002' && handleStatus(common.returnCode, dispatch);

      dispatch(logonActoinResult({ ...res.data, ...reqData }));
      dispatch(showHideLodingAction(false));

      if (!isError && common.returnCode === 'YANGGUAN-000') {
        const { sellerType } = reqData;

        sessionStorage.setItem('sellerType', sellerType);
        const accessToken = res.data.data.accessToken;

        sessionStorage.setItem('accessToken', accessToken);
        history.push('/home');
      }
    });
  };
};

const getOtpActionResult = (data) => {
  console.warn('data-->', data);
  return {
    type: SEND_OTP,
    data,
  };
};

export const getOtpAction = (reqData) => {
  return (dispatch) => {
    dispatch(serverErrorAction(false));
    sessionStorage.setItem('accessToken', '');

    post(links['verifySeller'], reqData, (res) => {
      const common = res.data.common;
      const accessToken = _.get(res, 'data.data.accessToken');

      sessionStorage.setItem('accessToken', accessToken);
      const isError =
        common.returnCode != 'YANGGUAN-002' && handleStatus(common.returnCode, dispatch);

      if (!isError) {
        dispatch(getOtpActionResult({ ...res.data }));
      }
    });
  };
};

const getEmarketActionResult = (data) => {
  return { type: GET_EMARKETS, data };
};

export const getEmarketAction = (params) => {
  return (dispatch) => {
    post(
      links.getEmarkets,
      params,
      (res) => {
        const { data, common } = res.data;

        if (data) {
          return dispatch(getEmarketActionResult(data));
        }

        handleStatus(common.returnCode, dispatch);
      },
      false,
    );
  };
};

const getPermissionActionResult = (data) => {
  return {
    type: GET_PERMISSION,
    data,
  };
};

export const getPermissionAction = () => {
  return (dispatch, getState) => {
    if (!checkIsLogin(getState())) {
      return;
    }

    dispatch(showHideLodingAction(true));
    get(links['getPermisssion'], {}, (res) => {
      const data = res.data;
      const common = res.data.common;
      const isError = handleStatus(common.returnCode, dispatch);

      if (!isError) {
        dispatch(getPermissionActionResult(data));
      }

      dispatch(showHideLodingAction(false));
    });
  };
};

const initSellerInfoAction = (data) => {
  return {
    type: INIT_SELLER_INFO,
    data,
  };
};

export const getSellerInfoAction = (reqData, dispatch, getState) => {
  if (!checkIsLogin(getState())) {
    return;
  }

  dispatch(showHideLodingAction(true));
  dispatch(serverErrorAction(false));
  get(links['getSellerInfo'], reqData, (res) => {
    const data = res.data.data;
    const common = res.data.common;
    const isError = handleStatus(common.returnCode, dispatch);

    if (!isError) {
      dispatch(initSellerInfoAction(data));
    }

    dispatch(showHideLodingAction(false));
  });
};

export const getBankInfoAction = (data) => {
  return (dispatch) => {
    dispatch(showHideLodingAction(true));
    dispatch(serverErrorAction(false));

    get(links['getBankInfo'], data, (res) => {
      const data = res.data.data;
      const common = res.data.common;
      const isError = handleStatus(common.returnCode, dispatch);

      if (!isError) {
        dispatch(getBankInfoActionResult(data));
      }

      dispatch(showHideLodingAction(false));
    });
  };
};

export const getBankInfoActionResult = (data) => {
  return {
    type: GET_BANK_INFO,
    data,
  };
};

export const updateSellerInfoAction = (reqData) => {
  return (dispatch) => {
    dispatch(serverErrorAction(false));
    dispatch(showHideLodingAction(true));

    post(links['updateSellerInfo'], reqData, (res) => {
      // debugger
      const common = res.data.common;
      const isError = handleStatus(common.returnCode, dispatch);

      if (!isError && common.returnCode === 'YANGGUAN-000') {
        history.replace('/success');
        sessionStorage.setItem('infoSellerStatus', reqData.additionalInfo.sellerStatus);
        dispatch(updateSellerInfoActionResult({ ...res, ...reqData }));
      } else {
        !isError && dispatch(updateSellerInfoActionResultWithError(res.data));
      }

      dispatch(showHideLodingAction(false));
    });
  };
};

const updateSellerInfoActionResult = (data) => {
  return {
    type: UPDATE_SELLER_INFO,
    data,
  };
};
const updateSellerInfoActionResultWithError = (data) => {
  return {
    type: UPDATE_SELLER_INFO_ERROR,
    data,
  };
};

export const logoffAction = (reqData) => {
  return (dispatch) => {
    get(links['logoff'], reqData, (res) => {
      dispatch(logoffActionResult(res));
      window.location.href = '/ecommerce/index.html#/login';
    });
    // dispatch(logoffActionResult())
  };
};

const logoffActionResult = (data) => {
  return {
    type: LOGOFF,
    data,
  };
};

export const getTrackListAction = (reqData) => {
  return (dispatch, getState) => {
    if (!checkIsLogin(getState())) {
      return;
    }

    dispatch(showHideLodingAction(true));
    dispatch(serverErrorAction(false));

    get(links['getTrackList'], reqData, (res) => {
      const common = res.data.common;
      const isError = handleStatus(common.returnCode, dispatch);

      if (!isError) {
        dispatch(getTrackListActionResult(res.data.data));
      }

      dispatch(showHideLodingAction(false));
    });
  };
};

const getTrackListActionResult = (data) => {
  return {
    type: GET_TRACK_LIST,
    data,
  };
};

export const fileListAction = (reqData, obj) => {
  const reg = /[#*^/\:"?<>@]+/g;
  const flag = reg.test(obj.fileId);
  return (dispatch) => {
    dispatch(serverErrorAction(false));
    post(links['uploadFilesStatus'], reqData, (res) => {
      const common = res.data.common;

      if (flag && common.returnCode === 'YANGGUAN-005') {
        const resData = {};

        resData.data = {
          common: common,
          data: obj,
        };
        dispatch(fileListActionResult(resData));
        return;
      }

      const isError = handleStatus(common.returnCode, dispatch);

      if (!isError) {
        dispatch(fileListActionResult(res));
      }
    });
  };
};

export const fileListActionResult = (data) => {
  return {
    type: UPLOAD_FILE_LIST,
    data,
  };
};

export const confirmUploadFilesAction = (reqData) => {
  return (dispatch, getState) => {
    if (!checkIsLogin(getState())) {
      return;
    }

    dispatch(serverErrorAction(false));

    post(links['confirmUploadFiles'], reqData, (res) => {
      const common = res.data.common;
      const isError =
        common.returnCode != 'YANGGUAN-002' && handleStatus(common.returnCode, dispatch);

      if (!isError) {
        dispatch(confirmUploadFilesResult(common.returnCode));
      }
    });
  };
};

const confirmUploadFilesResult = (data) => {
  return {
    type: CONFIRM_UPLOAD_FILES,
    data,
  };
};

export const continueSessionAction = (hash, isRenew) => {
  return (dispatch, getState) => {
    get(links['reNewSession'], { isRenew: isRenew }, (res) => {
      const data = res.data.data;
      const common = res.data.common;
      const isError = handleStatus(common.returnCode, dispatch);

      if (isRenew === false) {
        sessionStorage.setItem('isLogin', '');
      }

      console.log(hash);

      if (!isError && isRenew === true) {
        const state = getState();

        sessionStorage.setItem('accessToken', data.accessToken);

        if (hash.indexOf('info') > -1 && !state.info.additionalInfo.isHsbcUser) {
          const info = state.info;

          console.log(info);
          history.replace('info');
          window.location.reload();
        }

        if (hash.indexOf('upload') > -1 && !state.upload.poNumListSize) {
          const upload = state.upload;

          console.log(upload);
          history.replace('upload');
          window.location.reload();
        }

        if (hash.indexOf('home') > -1) {
          history.replace('home');
          window.location.reload();
        }
      }
    });
  };
};

export const normalliszer = (data) => {
  const lizerData = {};

  if (!data) {
    return;
  }

  const keys = Object.keys(data);
  const values = Object.values(data);

  for (var i = 0; i < keys.length; i++) {
    const value = values[i];

    if (typeof value != 'object' || value instanceof Array) {
      lizerData[keys[i]] = data[keys[i]];
    } else {
      normalliszer(values[i]);
    }
  }
  return lizerData;
};

export const getAreaDicAction = (reqData) => {
  return (dispatch, getState) => {
    if (!checkIsLogin(getState())) {
      return;
    }

    dispatch(serverErrorAction(false));
    dispatch(showHideLodingAction(true));
    get(links['getAreaDicData'], {}, (res) => {
      const data = res.data.data;
      const common = res.data.common;
      const isError = handleStatus(common.returnCode, dispatch);

      if (!isError) {
        getSellerInfoAction(reqData, dispatch, getState);
        dispatch(getAreaDicActionResult(data));
      } else {
        dispatch(showHideLodingAction(false));
      }
    });
  };
};

const getAreaDicActionResult = (data) => {
  return {
    type: AREA_DIC,
    data,
  };
};
