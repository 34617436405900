/* eslint-disable no-param-reassign */
import * as constants from './constants';

const defaultInfo = {
  additionalInfo: {},
  sellerDetail: {},
  bankInfos: [],
  pageInfo: {},
  errorFlagCodeList: [],
  totalPages: '',
  pageNo: '',
  totalElements: '',
  invalidMandatoryRule: false,
  invalidIndustryErrorMsgList: [],
  country1Error: false,
  countryErrorMsg: false,
  country1TypeError: false,
  ecoAndCountryError: {},
  ecoAndCompanyNameError: {},
  areaDic: {},
};
const ErrorCode = {
  'YANGGUAN-013': 'economicTypeError',
  'YANGGUAN-014': '外方投资国别名称出现重复',
  'YANGGUAN-015': '请选择外方投资者国别',
  'YANGGUAN-016': '外方投资国别与经济类型不符',
  'YANGGUAN-017': 'contactError',
  'YANGGUAN-018': 'postCodeError',
  'YANGGUAN-019': 'phoneError',
  'YANGGUAN-020': 'landlinePhoneError',
  // "YANGGUAN-043":"addressError",
  'YANGGUAN-044': '驻华办事处/代表处经济类型代码为非290/390',
  'YANGGUAN-045': '境内股份有限公司经济类型为非股份有限公司',
  'YANGGUAN-046': '内资有限责任公司经济类型为非有限责任公司',
  'YANGGUAN-047': '使领馆经济类型不为“400境外机构”',
  'YANGGUAN-048': '国家机关经济类型不为“110国有全资”',
  'YANGGUAN-049': '常驻国家与经济类型不符',
  'YANGGUAN-053': '请输入正确的“工艺品”行业属性代码',
  'YANGGUAN-054': '请输入正确的会计/律师事务所行业属性代码',
  'YANGGUAN-055': '请输入正确的修理/维修行业属性代码',
  'YANGGUAN-056': '请输入正确的国家机关行业属性代码',
  'YANGGUAN-057': '请输入正确的校园类单位行业属性代码',
  'YANGGUAN-058': '请输入正确的酒店旅馆类单位行业属性代码',
  'YANGGUAN-059': '请输入正确的咨询行业属性代码',
  'YANGGUAN-060': '请输入正确的广告行业属性代码',
  'YANGGUAN-061': '请输入正确的旅游行业属性代码',
  'YANGGUAN-062': '请输入正确的商进出口/商贸/贸易业属性代码',
  'YANGGUAN-063': 'addressError',
};
const ecoAndCountryErrorCodeList = [
  'YANGGUAN-044',
  'YANGGUAN-045',
  'YANGGUAN-046',
  'YANGGUAN-047',
  'YANGGUAN-048',
];
const ecoAndCompanyNameErrorCodeList = ['YANGGUAN-049'];
const foreignCountryErrorCodeList = ['YANGGUAN-050', 'YANGGUAN-051', 'YANGGUAN-052'];
const industryErrorCodeList = [
  'YANGGUAN-053',
  'YANGGUAN-054',
  'YANGGUAN-055',
  'YANGGUAN-056',
  'YANGGUAN-057',
  'YANGGUAN-058',
  'YANGGUAN-059',
  'YANGGUAN-060',
  'YANGGUAN-061',
  'YANGGUAN-062',
];
const errorFlagList = [
  'YANGGUAN-013',
  'YANGGUAN-017',
  'YANGGUAN-018',
  'YANGGUAN-019',
  'YANGGUAN-020',
  'YANGGUAN-063',
];

export default (state = defaultInfo, action) => {
  if (action.type === constants.INIT_SELLER_INFO) {
    return {
      ...state,
      sellerDetail: action.data.sellerDetail,
      additionalInfo: action.data.additionalInfo,
      bankInfos: [],
    };
  }

  if (action.type === constants.UPDATE_SELLER_INFO) {
    return { ...state, sellerDetail: {}, additionalInfo: {} };
  }

  if (action.type === constants.UPDATE_SELLER_INFO_ERROR) {
    // debugger
    const returnCode = action.data.common.returnCode;
    const errorList = returnCode === 'YANGGUAN-066' && Object.keys(action.data.data);
    const invalidIndustryErrorMsg = [];
    let invalidMandatoryRule = false;
    let country1Error = false;
    let countryErrorMsg = false;
    let country1TypeError = false;
    //常驻国家（地区）与经济类型不符
    let ecoAndCountryError = {
      isPass: false,
      errorInfo: '',
    };
    //企业名称与经济类型不符
    let ecoAndCompanyNameError = {
      isPass: false,
      errorInfo: '',
    };
    const errorFlagCodeList = [];

    errorList.map((item) => {
      if (industryErrorCodeList.indexOf(item) > -1) {
        invalidIndustryErrorMsg.push(ErrorCode[item]);
        invalidMandatoryRule = true;
      }

      if (ecoAndCountryErrorCodeList.indexOf(item) > -1) {
        ecoAndCountryError = {
          isPass: true,
          errorInfo: ErrorCode[item],
        };
      }

      if (ecoAndCompanyNameErrorCodeList.indexOf(item) > -1) {
        ecoAndCompanyNameError = {
          isPass: true,
          errorInfo: ErrorCode[item],
        };
      }

      if (foreignCountryErrorCodeList.indexOf(item) > -1) {
        //重复
        if (item === 'YANGGUAN-014') {
          country1Error = true;
          countryErrorMsg = true;
        }

        //为空请选择
        if (item === 'YANGGUAN-015') {
          country1Error = true;
          countryErrorMsg = false;
        }

        //外方投资者国别不符
        if (item === 'YANGGUAN-016') {
          country1Error = false;
          countryErrorMsg = false;
          country1TypeError = true;
        }
      }

      if (errorFlagList.indexOf(item) > -1) {
        errorFlagCodeList.push(ErrorCode[item]);
      }
    });
    return {
      ...state,
      invalidMandatoryRule,
      invalidIndustryErrorMsgList: invalidIndustryErrorMsg,
      ecoAndCountryError,
      ecoAndCompanyNameError,
      country1Error,
      countryErrorMsg,
      country1TypeError,
      errorFlagCodeList,
    };
  }

  if (action.type === constants.GET_BANK_INFO) {
    const { bankInfos, pageNo, totalPages, totalElements } = action.data;
    var bankInfoData = [];

    bankInfoData = bankInfos.map((item, index) => {
      item['key'] = index;
      return item;
    });
    return {
      ...state,
      pageNo: pageNo,
      bankInfos: bankInfoData,
      totalPages: totalPages,
      totalElements: totalElements,
    };
  }

  if (action.type === constants.AREA_DIC) {
    return { ...state, areaDic: action.data };
  }
  return state;
};
