import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import loadingImag from '../../assets/img/preloading.gif';

import './style.scss';

class LoadingComponent extends React.Component {
  // constructor(props){
  //     super(props)
  // }
  render() {
    const { showLoading } = this.props;

    if (showLoading) {
      return (
        <div className="loading-wrapper">
          <img src={loadingImag} alt="loading" />
        </div>
      );
    } else {
      return '';
    }
  }
}
LoadingComponent.propTypes = {
  showLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  showLoading: state.logon.showLoading,
});

export default connect(mapStateToProps, {})(LoadingComponent);
